/* eslint-disable func-names */
/* eslint-disable react/function-component-definition */
import Head from "next/head";
import React from "react";

type HeaderProps = {
  title: string;
};

const Header: React.FunctionComponent<HeaderProps> = ({ title }) => {
  const message = `Raraity | ${title}`;
  return (
    <>
      <Head>
        <title>{message}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          property="og:image"
          content="https://raraityart55525-prod.s3.us-west-2.amazonaws.com/public/detailphotos/framedartstudio2.png"
        />
        <meta property="og:title" content="AI-Powered Art Studio" />
        <meta
          property="og:description"
          content="AI-powered Art Studio Making Premium Design a Quick and Custom Experience."
        />
      </Head>
    </>
  );
};

export default Header;
